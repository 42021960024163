.error-message-container {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.error-message-text {
  margin: 0;
  text-align: center;
}

.loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App {
  text-align: center;
}

.create-input {
  width: 50%;
  margin-bottom: 1em;
  font-size: 1em;
  padding: 1em;
  border: 1px #ccc solid;
  border-radius: 10em;
}

.main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-bottom: 2px #F28C28 solid;
  margin-bottom: 2em;
  height: 7em;
}

.phone-input {
  height: 2em;
  width: 2em;
  font-size: 1,5em;
  margin-right: .5em;
}

.success-message-container {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.success-message-text {
  margin: 0;
}